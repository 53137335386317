@import '../../web-common/styles/variables.scss';

.warning-box {
  background-color: $warning-color;
  padding: 10px 15px;
  border-radius: 10px;
  margin: 30px 0;
  $warning-font-size: 16px;
  font-size: $warning-font-size;
  $regular-text-color: rgba(0, 0, 0, 0.8);

  p {
    margin: 0;
  }

  a {
    color: $regular-text-color;
    text-decoration: underline;
    font-weight: unset;

    &:hover::after {
      display: none;
    }
  }

  code {
    color: $regular-text-color;
    background-color: unset;
  }

  h4 {
    font-size: 14px;
    margin: 0 0 10px 0;
    font-weight: 400;
    font-style: italic;
    color: rgba(0, 0, 0, 0.6);
  }

  ul {
    margin: 0;
    li {
      font-size: $warning-font-size;
    }
  }

  strong {
    font-weight: 400;
  }
}
