@import '../../../web-common/styles/variables.scss';

.release-view {

  $divider-color: rgba(0, 0, 0, 0.3);
  margin-top: 60px;

  strong {
    $space: 20px;
    border-right: 1px solid $divider-color;
    margin-right: $space;
    padding-right: $space;
  }

  time {
    font-family: $default-font;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.5);
    font-size: 16px;
  }

  h2 {
    margin-bottom: 0;
    font-size: 30px;
    display: flex;
    align-items: center;
  }

  hr {
    border: none;
    border-top: 1px solid $divider-color;
    margin: 10px 0 0;
  }

  .release-notes {
    font-size: 16px;
    overflow-x: scroll;
    padding-left: 20px;

    > ul {
      margin-top: 0;
    }

    > p {
      margin: 30px 0 10px;
    }
  }
}
