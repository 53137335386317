@import '../../../styles/variables.scss';

.account-btn {
  position: relative;
  margin-left: 15px;
  $size: 33px;
  button {
    display: block;
    cursor: pointer;
    width: $size;
    height: $size;
    img {
      width: 100%;
    }
    @media (max-width: 500px) {
      width: 25px;
      height: 25px;
    }
  }

  .dropdown {
    font-family: $default-font;
    position: absolute;
    right: 0;
    top: 44px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0,0,0,.1);
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    z-index: 1;
    a {
      display: block;
      padding: 5px 15px;
      cursor: pointer;
      font-weight: 300;
    }
  }

  .click-detector {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
}
