@charset "UTF-8";
.webview-description .description {
  font-size: 20px;
  font-weight: 300;
  margin: 50px 0 30px;
  font-family: 'Roboto'; }

.webview-description ul.feature-list {
  list-style-type: '•'; }
  .webview-description ul.feature-list li {
    padding-left: 10px;
    margin: 18px 0; }
  .webview-description ul.feature-list li::marker {
    font-family: monospace; }
