@import '../../variables.scss';
@import '../../web-common/components/Sidebar/variables.scss';

.app {
  $sidebar-width-plus-margin: $sidebar-width + 30px;
  padding-bottom: 100px;

  .developers-navbar {
    background-color: $theme-color-1;
  }

  .app-container {
    width: $page-content-width;
    max-width: $page-content-max-width;
    margin: 80px auto 0 auto;
    display: flex;
    justify-content: space-between;
    @media (max-width: $sidebar-collapse-threshold + 0.001px) {
      display: block;
    }
  }

  .page-container {

    @media (min-width: $sidebar-collapse-threshold + 0.001px) {
      /*
      * String interpolation is needed here due to a Sass issue:
      * https://stackoverflow.com/questions/17982111/sass-variable-in-css-calc-function
      */
      $width: calc(#{$page-content-width} - #{$sidebar-width-plus-margin});
      flex: 0 0 $width;
      width: $width;
    }

    @media (min-width: $page-width-at-which-content-is-at-max-width) {
      $width: calc(#{$page-content-max-width} - #{$sidebar-width-plus-margin});
      flex: 0 0 $width;
      width: $width;
    }
  }
}
