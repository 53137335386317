@import './variables.scss';

.link-font {
  color: $link-color;
  font-weight: 400;

  b > & {
    font-weight: inherit;
  }
}
