@import '../../../variables.scss';

.member-section {
  margin-top: 50px;

  > div {
    overflow-x: scroll;
    margin: 30px 0 60px 0;

    h3 {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 15px;
    }
  }

  .full-name {
    margin-bottom: 30px;
    font-weight: 300;
    color: inherit;
    background-color: #f7f7f9;
    a::after {
      display: none;
    }
    a:hover::after {
      display: block;
    }
  }

  .description {
    margin: 20px 0;
    line-height: 1.7;
  }

  .seealso {
    margin-top: 20px;
    font-size: 16px;
    strong {
      font-weight: 400;
    }
    li p {
      margin: 10px 0;
    }
    ul {
      margin: 5px 0;
    }
  }
}
