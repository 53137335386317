
.store-links {

  display: flex;
  flex-wrap: wrap;
  // compensate for the links' margin-top: 10px;
  margin-top: -10px;
  $border-radius: 10px;
  $padding: 0 15px;

  > a, > div {
    flex: 0 0 auto;
    color: white;
    font-weight: 400;
    height: 48px;
    display: flex;
    align-items: center;
    padding: $padding;
    border-radius: $border-radius;
    margin-top: 10px;
    margin-right: 15px;
    transition: background-color 0.5s linear, transform 0.1s ease-in-out;
    cursor: pointer;
    // Needed for Safari.
    white-space: nowrap;

    img {
      height: 27px;
      margin-right: 5px;
      opacity: 0.9;
      &:last-of-type {
        margin-right: 10px;
      }
    }

    &:hover {
      transition-duration: 0.15s;
      text-decoration: none;
      transform: scale(1.03);
      img {
        opacity: 1;
      }
    }
  }

  .standalone {
    $color: #129dc4;
    background-color: $color;
    &:hover {
      background-color: lighten($color, 5%);
    }
  }

  $android-color: rgb(138, 168, 48);
  .android {
    position: relative;
    // Make sure the dropdown renders in front of stuff
    // (like the video on the developer overview page)
    z-index: 1;
    padding: 0;
    cursor: default;
    > div:first-child {
      background-color: $android-color;
      display: flex;
      align-items: center;
      position: relative;
      // render in front of the dropdown
      z-index: 1;
      padding: $padding;
      height: 100%;
      border-radius: $border-radius;
    }

    &:hover > div:first-child {
      background-color: lighten($android-color, 5%);
    }
  }

  .dropdown {
    position: absolute;
    top: 35px;
    right: 0;
    left: 0;
    overflow: hidden;

    > div {
      padding-top: 15px;
      padding-bottom: 5px;
      background-color: $android-color;
      // background-color: lighten($android-color, 5%);
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      position: relative;
      top: -100px;
      transition: top 0.25s ease-in-out;
    }

    a {
      display: block;
      font-weight: 300;
      color: white;
      font-size: 14px;
      padding: 5px 10px;
      text-align: center;
    }
  }

  .android:hover .dropdown > div {
    top: 0;
  }

  $apple-color: #1d1d1f;

  .ios {
    background-color: $apple-color;
    &:hover {
      background-color: lighten($apple-color, 5%);
    }
    img {
      position: relative;
      top: -2px;
    }
  }

  .visionos {
    background-color: $apple-color;

    &:hover {
      background-color: lighten($apple-color, 5%);
    }

    // img {
    //   position: relative;
    //   top: -2px;
    // }
  }

  .webgl {
    $color: #e74c1d;
    background-color: $color;
    &:hover {
      background-color: lighten($color, 5%);
    }
  }

  .uwp {
    $color: rgb(97, 42, 139);
    background-color: $color;
    &:hover {
      background-color: lighten($color, 5%);
    }
    img {
      height: 20px;
    }
  }
}
