
.page {
  line-height: 1.5;

  li + li, ul + li, li + ul {
    margin-top: 12px;
  }

  > ol {
    padding-inline-start: 1em;

    > li {
      margin-top: 15px;
    }

    > li > ol > li {
      margin-top: 6px;
    }
  }

  li > ul {
    margin-top: 12px;
    font-size: 14px;
  }

  ul {
    padding-inline-start: 40px;
  }

  h2 {
    margin-top: 40px;
  }
}
