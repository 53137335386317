
.releases-page {

  .spinner-container {
    margin-top: 60px;
    max-width: 320px;
  }

  .spinner {
    margin: 0 auto;
  }
}
