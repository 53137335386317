@import '../../web-common/styles/variables.scss';

.file-reference {

  h2 {
    color: rgba(0, 0, 0, 0.65);
    font-weight: 400;
    font-family: $default-font;

    a {
      // color: rgba(0, 0, 0, 0.65);
      font-weight: 400;
      &:hover {
        text-decoration: underline;
        &::after {
          display: none !important;
        }
      }
    }
  }

  h3 {
    font-family: $default-font;
  }

  > div:first-child {
    h1 {
      font-size: 30px;
      display: inline-block;
      margin-right: 15px;
      margin-bottom: 5px;
      font-family: 'Roboto';
    }

    h2 {
      display: inline-block;
      color: rgba(0, 0, 0, 0.5);
      font-size: 20px;
      margin-bottom: 5px;
    }

    h3 {
      font-weight: 300;
      color: rgba(0, 0, 0, 0.3);
      font-size: 15px;
      margin: 0 0 30px;
    }
  }

  > p, li {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.8);
    line-height: 1.7;
  }

  ul {
    padding-inline-start: 30px;
    li {
      margin: 5px 0;
    }
  }

  .member-summary {
    > h2 {
      margin: 40px 0 10px 0;
    }
  }

  .seealso {
    font-size: 16px;
    margin: 30px 0;
    strong {
      font-weight: 400;
    }
    li p {
      margin: 0;
      line-height: 1;
    }
    ul {
      margin: 5px 0;
    }
    p {
      font-size: 16px;
    }
  }
}
