
.search-input {

  $width-padding: 5%;
  display: flex;
  align-items: center;
  max-width: 300px;
  width: 100%;

  input {
    border: none;
    font-family: inherit;
    border-radius: 15px;
    font-size: 1em;
    padding: 5px 15px;
    width: calc(#{100% - 2 * $width-padding});
    outline: none;
  }

  button {
    $size: 33px;
    width: $size;
    height: $size;
    border: none;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    margin-left: 5px;
    outline: none;
    cursor: pointer;
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
    &:active {
      background-color: rgba(255, 255, 255, 0.1);
    }
    &:disabled {
      background-color: rgba(255, 255, 255, 0.3);
      cursor: default;
    }
  }

  img {
    $size: 80%;
    width: $size;
    height: $size;
  }
}
