
.getting-started {
  h2 {
    margin-top: 40px;
  }

  img {
    display: block;
    width: 100%;
    max-width: 700px;
  }
}
