

.navbar {
  $sidebar-collapse-threshold: 900px;
  $mobile-width-threshold: 750px;
  position: absolute; top: 0; left: 0; right: 0;
  $navbar-height: 50px;
  $navbar-mobile-height: 30px;
  font-family: 'Futura';
  color: white;
  height: $navbar-height;
  border-bottom: 1px solid white;

  > div {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .nav-side {
    display: flex;
    align-items: center;
  }

  .nav-right {
    display: flex;
    align-items: center;
  }

  .nav-logo {
    width: 34px;
    display: block;

    @media (max-width: $mobile-width-threshold) {
      width: 20px;
    }
    img {
      width: 100%;
    }
  }

  .nav-slash {
    height: 44px;
    width: 2px;
    background-color: white;
    margin: 0 18px 0 5px;
    transform: rotate(30deg);

    @media (max-width: $mobile-width-threshold) {
      height: 30px;
      margin: 0 10px 0 5px;
    }
  }

  .nav-header {
    font-family: 'Futura';
    font-size: 28px;
    display: block;
    color: white;

    @media (max-width: $mobile-width-threshold) {
      font-size: 1em;
    }
    @media (max-width: 420px) {
      font-size: 0.8em;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  a::after {
    display: none !important;
  }

  .nav-center {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $mobile-width-threshold) {
      height: $navbar-mobile-height;
      margin-left: 10px;
    }

    > a {
      color: inherit;
      text-decoration: none;
      font-size: 1.2em;
      margin: 0 20px;
      cursor: pointer;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;

      &:last-of-type {
        margin-right: 0;
      }

      &:hover {
        text-decoration: underline;
      }

      @media (max-width: $mobile-width-threshold) {
        font-size: 1em;
        margin: 0 15px;
      }
      @media (max-width: 420px) {
        font-size: 14px;
        margin: 0 10px;
      }
      @media (max-width: 350px) {
        margin: 0 5px;
      }
    }

    .social-links {
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0 20px;

      @media (max-width: $mobile-width-threshold) {
        display: none;
      }
    }

    .social-link {
      height: 25px;
      width: 25px;
      margin: 0 5px;
      position: relative;
      display: block;

      @media (max-width: $mobile-width-threshold) {
        height: 20px;
        width: 20px;
      }
      svg {
        width: 100%;
        height: 100%;
      }

      &:hover::after {
        content: '';
        display: block !important;
        $size: -5px;
        position: absolute; top: $size; right: $size; bottom: $size; left: $size;
        border-radius: 50%;
        border: 2px solid white;
        background-color: unset;
        height: unset;
        opacity: 0.7;
      }
    }
  }

  &.search-enabled {
    @media (max-width: 900px) {
      .nav-center {
        display: none;
      }
    }
    @media (max-width: 525px) {
      .nav-logo, .nav-slash, .nav-header {
        display: none;
      }
    }
  }

  .menu-button {
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    color: white;
    font-size: 40px;
    outline: none;
    cursor: pointer;
    position: relative;

    @media (min-width: $sidebar-collapse-threshold + 0.001px) {
      display: none;
    }
    @media (max-width: 350px) {
      width: 18px;
      height: 16px;
      margin-right: 10px;
    }

    .close {
      text-align: center;
      position: relative; top: -5px;
    }
  }

  .hamburger {
    width: 100%;
    height: 21px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > div {
      height: 3px;
      background-color: white;
      border-radius: 10px;
    }
  }

}
