@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:400,500);
.search-input {
  display: flex;
  align-items: center;
  max-width: 300px;
  width: 100%; }
  .search-input input {
    border: none;
    font-family: inherit;
    border-radius: 15px;
    font-size: 1em;
    padding: 5px 15px;
    width: calc(90%);
    outline: none; }
  .search-input button {
    width: 33px;
    height: 33px;
    border: none;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    margin-left: 5px;
    outline: none;
    cursor: pointer; }
    .search-input button:hover {
      background-color: rgba(255, 255, 255, 0.2); }
    .search-input button:active {
      background-color: rgba(255, 255, 255, 0.1); }
    .search-input button:disabled {
      background-color: rgba(255, 255, 255, 0.3);
      cursor: default; }
  .search-input img {
    width: 80%;
    height: 80%; }

/*
* CSS reset for buttons.
* https://gist.github.com/MoOx/9137295
*/
.button-reset {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  /* Remove excess padding and border in Firefox 4+ */ }
  .button-reset::-moz-focus-inner {
    border: 0;
    padding: 0; }

.account-btn {
  position: relative;
  margin-left: 15px; }
  .account-btn button {
    display: block;
    cursor: pointer;
    width: 33px;
    height: 33px; }
    .account-btn button img {
      width: 100%; }
    @media (max-width: 500px) {
      .account-btn button {
        width: 25px;
        height: 25px; } }
  .account-btn .dropdown {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    position: absolute;
    right: 0;
    top: 44px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    z-index: 1; }
    .account-btn .dropdown a {
      display: block;
      padding: 5px 15px;
      cursor: pointer;
      font-weight: 300; }
  .account-btn .click-detector {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1; }

.navbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-family: 'Futura';
  color: white;
  height: 50px;
  border-bottom: 1px solid white; }
  .navbar > div {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between; }
  .navbar .nav-side {
    display: flex;
    align-items: center; }
  .navbar .nav-right {
    display: flex;
    align-items: center; }
  .navbar .nav-logo {
    width: 34px;
    display: block; }
    @media (max-width: 750px) {
      .navbar .nav-logo {
        width: 20px; } }
    .navbar .nav-logo img {
      width: 100%; }
  .navbar .nav-slash {
    height: 44px;
    width: 2px;
    background-color: white;
    margin: 0 18px 0 5px;
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg); }
    @media (max-width: 750px) {
      .navbar .nav-slash {
        height: 30px;
        margin: 0 10px 0 5px; } }
  .navbar .nav-header {
    font-family: 'Futura';
    font-size: 28px;
    display: block;
    color: white; }
    @media (max-width: 750px) {
      .navbar .nav-header {
        font-size: 1em; } }
    @media (max-width: 420px) {
      .navbar .nav-header {
        font-size: 0.8em; } }
    .navbar .nav-header:hover {
      text-decoration: underline; }
  .navbar a::after {
    display: none !important; }
  .navbar .nav-center {
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (max-width: 750px) {
      .navbar .nav-center {
        height: 30px;
        margin-left: 10px; } }
    .navbar .nav-center > a {
      color: inherit;
      text-decoration: none;
      font-size: 1.2em;
      margin: 0 20px;
      cursor: pointer;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center; }
      .navbar .nav-center > a:last-of-type {
        margin-right: 0; }
      .navbar .nav-center > a:hover {
        text-decoration: underline; }
      @media (max-width: 750px) {
        .navbar .nav-center > a {
          font-size: 1em;
          margin: 0 15px; } }
      @media (max-width: 420px) {
        .navbar .nav-center > a {
          font-size: 14px;
          margin: 0 10px; } }
      @media (max-width: 350px) {
        .navbar .nav-center > a {
          margin: 0 5px; } }
    .navbar .nav-center .social-links {
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0 20px; }
      @media (max-width: 750px) {
        .navbar .nav-center .social-links {
          display: none; } }
    .navbar .nav-center .social-link {
      height: 25px;
      width: 25px;
      margin: 0 5px;
      position: relative;
      display: block; }
      @media (max-width: 750px) {
        .navbar .nav-center .social-link {
          height: 20px;
          width: 20px; } }
      .navbar .nav-center .social-link svg {
        width: 100%;
        height: 100%; }
      .navbar .nav-center .social-link:hover::after {
        content: '';
        display: block !important;
        position: absolute;
        top: -5px;
        right: -5px;
        bottom: -5px;
        left: -5px;
        border-radius: 50%;
        border: 2px solid white;
        background-color: unset;
        height: unset;
        opacity: 0.7; }
  @media (max-width: 900px) {
    .navbar.search-enabled .nav-center {
      display: none; } }
  @media (max-width: 525px) {
    .navbar.search-enabled .nav-logo, .navbar.search-enabled .nav-slash, .navbar.search-enabled .nav-header {
      display: none; } }
  .navbar .menu-button {
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    color: white;
    font-size: 40px;
    outline: none;
    cursor: pointer;
    position: relative; }
    @media (min-width: 900.001px) {
      .navbar .menu-button {
        display: none; } }
    @media (max-width: 350px) {
      .navbar .menu-button {
        width: 18px;
        height: 16px;
        margin-right: 10px; } }
    .navbar .menu-button .close {
      text-align: center;
      position: relative;
      top: -5px; }
  .navbar .hamburger {
    width: 100%;
    height: 21px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .navbar .hamburger > div {
      height: 3px;
      background-color: white;
      border-radius: 10px; }

.sidebar {
  width: 250px;
  flex: 0 0 250px; }
  @media (max-width: 900px) {
    .sidebar {
      display: none; } }
  .sidebar.mobile-only-sidebar {
    display: none; }
  .sidebar.mobile-open-container {
    position: fixed;
    top: 50px;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: block; }
  .sidebar .mobile-only {
    display: none; }
  .sidebar .mobile-open {
    height: 100%;
    overflow-y: scroll; }
    .sidebar .mobile-open .mobile-only {
      display: block; }
  .sidebar .mobile-sidebar-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1; }
  .sidebar .desktop-sidebar {
    border-radius: 6px; }
  .sidebar aside {
    background-color: #edf5f7;
    padding: 15px 0; }
    .sidebar aside .sidebar-item, .sidebar aside a, .sidebar aside h2 {
      display: block;
      color: rgba(0, 0, 0, 0.8);
      font-size: 16px;
      margin: 15px;
      font-weight: 500; }
    .sidebar aside a:hover::after {
      display: none; }
    .sidebar aside h2 {
      font-family: unset;
      margin-bottom: 10px; }
    .sidebar aside h3 {
      margin: 15px 0 10px;
      font-size: 14px;
      font-weight: 400;
      font-family: unset;
      opacity: 0.5; }
    .sidebar aside a[href]:hover {
      text-decoration: underline; }
    .sidebar aside ul {
      list-style-type: none;
      -webkit-padding-start: 0;
              padding-inline-start: 0; }
      .sidebar aside ul a {
        font-weight: 300 !important;
        opacity: 0.85; }
        .sidebar aside ul a:first-child {
          margin-top: 5px; }
    .sidebar aside .file-section-list {
      -webkit-padding-start: 30px;
              padding-inline-start: 30px;
      margin: 0; }

.code-example-box {
  background-color: #eaeef3;
  border-radius: 10px;
  padding-top: 10px; }
  .code-example-box h4 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
    margin-left: 15px;
    color: rgba(0, 0, 0, 0.6);
    font-style: italic; }
  .code-example-box pre {
    margin-top: 0;
    font-size: 16px; }

.carousel-component {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .carousel-component > div {
    flex: 0 0 80%;
    position: relative; }
    .carousel-component > div > div {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow-x: hidden;
      display: flex;
      align-items: center; }
  .carousel-component button {
    flex: 0 0 10%;
    min-width: 30px;
    max-width: 50px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer; }
    .carousel-component button img {
      width: 100%;
      opacity: 0.5; }
    .carousel-component button:first-child {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); }
    .carousel-component button:last-child {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg); }
  .carousel-component .carousel-component-item {
    position: absolute; }

.customers-section {
  max-width: 920px; }
  .customers-section a img {
    width: 100%; }

@charset "UTF-8";
.webview-description .description {
  font-size: 20px;
  font-weight: 300;
  margin: 50px 0 30px;
  font-family: 'Roboto'; }

.webview-description ul.feature-list {
  list-style-type: '\2022'; }
  .webview-description ul.feature-list li {
    padding-left: 10px;
    margin: 18px 0; }
  .webview-description ul.feature-list li::marker {
    font-family: monospace; }

.store-links {
  display: flex;
  flex-wrap: wrap;
  margin-top: -10px; }
  .store-links > a, .store-links > div {
    flex: 0 0 auto;
    color: white;
    font-weight: 400;
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    border-radius: 10px;
    margin-top: 10px;
    margin-right: 15px;
    -webkit-transition: background-color 0.5s linear, -webkit-transform 0.1s ease-in-out;
    transition: background-color 0.5s linear, -webkit-transform 0.1s ease-in-out;
    transition: background-color 0.5s linear, transform 0.1s ease-in-out;
    transition: background-color 0.5s linear, transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
    cursor: pointer;
    white-space: nowrap; }
    .store-links > a img, .store-links > div img {
      height: 27px;
      margin-right: 5px;
      opacity: 0.9; }
      .store-links > a img:last-of-type, .store-links > div img:last-of-type {
        margin-right: 10px; }
    .store-links > a:hover, .store-links > div:hover {
      -webkit-transition-duration: 0.15s;
              transition-duration: 0.15s;
      text-decoration: none;
      -webkit-transform: scale(1.03);
              transform: scale(1.03); }
      .store-links > a:hover img, .store-links > div:hover img {
        opacity: 1; }
  .store-links .standalone {
    background-color: #129dc4; }
    .store-links .standalone:hover {
      background-color: #14b0db; }
  .store-links .android {
    position: relative;
    z-index: 1;
    padding: 0;
    cursor: default; }
    .store-links .android > div:first-child {
      background-color: #8aa830;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1;
      padding: 0 15px;
      height: 100%;
      border-radius: 10px; }
    .store-links .android:hover > div:first-child {
      background-color: #9abc36; }
  .store-links .dropdown {
    position: absolute;
    top: 35px;
    right: 0;
    left: 0;
    overflow: hidden; }
    .store-links .dropdown > div {
      padding-top: 15px;
      padding-bottom: 5px;
      background-color: #8aa830;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      position: relative;
      top: -100px;
      -webkit-transition: top 0.25s ease-in-out;
      transition: top 0.25s ease-in-out; }
    .store-links .dropdown a {
      display: block;
      font-weight: 300;
      color: white;
      font-size: 14px;
      padding: 5px 10px;
      text-align: center; }
  .store-links .android:hover .dropdown > div {
    top: 0; }
  .store-links .ios {
    background-color: #1d1d1f; }
    .store-links .ios:hover {
      background-color: #29292c; }
    .store-links .ios img {
      position: relative;
      top: -2px; }
  .store-links .visionos {
    background-color: #1d1d1f; }
    .store-links .visionos:hover {
      background-color: #29292c; }
  .store-links .webgl {
    background-color: #e74c1d; }
    .store-links .webgl:hover {
      background-color: #e95e34; }
  .store-links .uwp {
    background-color: #612a8b; }
    .store-links .uwp:hover {
      background-color: #6f309f; }
    .store-links .uwp img {
      height: 20px; }

.page, .webview-overview {
  line-height: 1.5; }
  .page li + li, .webview-overview li + li, .page ul + li, .webview-overview ul + li, .page li + ul, .webview-overview li + ul {
    margin-top: 12px; }
  .page > ol, .webview-overview > ol {
    -webkit-padding-start: 1em;
            padding-inline-start: 1em; }
    .page > ol > li, .webview-overview > ol > li {
      margin-top: 15px; }
    .page > ol > li > ol > li, .webview-overview > ol > li > ol > li {
      margin-top: 6px; }
  .page li > ul, .webview-overview li > ul {
    margin-top: 12px;
    font-size: 14px; }
  .page ul, .webview-overview ul {
    -webkit-padding-start: 40px;
            padding-inline-start: 40px; }
  .page h2, .webview-overview h2 {
    margin-top: 40px; }

.webview-overview h1 {
  font-family: Roboto;
  font-weight: 300;
  font-size: 31px; }
  .webview-overview h1 strong {
    font-family: Futura;
    font-size: 32px; }

.webview-overview .store-header {
  font-size: 16px;
  font-weight: bold;
  margin: 40px 0 10px;
  font-family: 'Roboto'; }

.webview-overview > video {
  display: block;
  width: 100%;
  max-width: 862px;
  border-radius: 6px;
  margin: 30px 0; }

.page {
  line-height: 1.5; }
  .page li + li, .page ul + li, .page li + ul {
    margin-top: 12px; }
  .page > ol {
    -webkit-padding-start: 1em;
            padding-inline-start: 1em; }
    .page > ol > li {
      margin-top: 15px; }
    .page > ol > li > ol > li {
      margin-top: 6px; }
  .page li > ul {
    margin-top: 12px;
    font-size: 14px; }
  .page ul {
    -webkit-padding-start: 40px;
            padding-inline-start: 40px; }
  .page h2 {
    margin-top: 40px; }

.getting-started h2 {
  margin-top: 40px; }

.getting-started img {
  display: block;
  width: 100%;
  max-width: 700px; }

.warning-box {
  background-color: #fff5cc;
  padding: 10px 15px;
  border-radius: 10px;
  margin: 30px 0;
  font-size: 16px; }
  .warning-box p {
    margin: 0; }
  .warning-box a {
    color: rgba(0, 0, 0, 0.8);
    text-decoration: underline;
    font-weight: unset; }
    .warning-box a:hover::after {
      display: none; }
  .warning-box code {
    color: rgba(0, 0, 0, 0.8);
    background-color: unset; }
  .warning-box h4 {
    font-size: 14px;
    margin: 0 0 10px 0;
    font-weight: 400;
    font-style: italic;
    color: rgba(0, 0, 0, 0.6); }
  .warning-box ul {
    margin: 0; }
    .warning-box ul li {
      font-size: 16px; }
  .warning-box strong {
    font-weight: 400; }

.member-section {
  margin-top: 50px; }
  .member-section > div {
    overflow-x: scroll;
    margin: 30px 0 60px 0; }
    .member-section > div h3 {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 15px; }
  .member-section .full-name {
    margin-bottom: 30px;
    font-weight: 300;
    color: inherit;
    background-color: #f7f7f9; }
    .member-section .full-name a::after {
      display: none; }
    .member-section .full-name a:hover::after {
      display: block; }
  .member-section .description {
    margin: 20px 0;
    line-height: 1.7; }
  .member-section .seealso {
    margin-top: 20px;
    font-size: 16px; }
    .member-section .seealso strong {
      font-weight: 400; }
    .member-section .seealso li p {
      margin: 10px 0; }
    .member-section .seealso ul {
      margin: 5px 0; }

.member-table {
  margin: 40px 0; }
  .member-table h3 {
    font-size: 18px;
    font-weight: 500;
    background-color: #edf5f7;
    padding: 12px 8px;
    margin: 0; }
  .member-table > div {
    font-family: 'Roboto Mono';
    font-size: 16px;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-top: none;
    overflow-x: scroll;
    white-space: nowrap; }
    .member-table > div a::after {
      display: none; }
    .member-table > div a:hover::after {
      display: block; }

.additional-interfaces {
  color: rgba(0, 0, 0, 0.6);
  margin: 10px 0 40px 30px;
  position: relative; }
  .additional-interfaces span {
    font-size: 16px;
    position: relative; }
  .additional-interfaces ul {
    margin: 5px 0;
    list-style-type: '- '; }
  .additional-interfaces li {
    line-height: 1;
    margin: 10px 0; }
  .additional-interfaces a {
    font-weight: 300; }
  .additional-interfaces .help-link {
    width: 15px;
    height: 15px;
    background-color: #6dd6f4;
    border-radius: 50%;
    color: white;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    font-weight: 400; }
    .additional-interfaces .help-link:hover {
      text-decoration: none;
      background-color: #12bae9; }

.file-reference h2 {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  font-family: "Roboto", Helvetica, Arial, sans-serif; }
  .file-reference h2 a {
    font-weight: 400; }
    .file-reference h2 a:hover {
      text-decoration: underline; }
      .file-reference h2 a:hover::after {
        display: none !important; }

.file-reference h3 {
  font-family: "Roboto", Helvetica, Arial, sans-serif; }

.file-reference > div:first-child h1 {
  font-size: 30px;
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 5px;
  font-family: 'Roboto'; }

.file-reference > div:first-child h2 {
  display: inline-block;
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  margin-bottom: 5px; }

.file-reference > div:first-child h3 {
  font-weight: 300;
  color: rgba(0, 0, 0, 0.3);
  font-size: 15px;
  margin: 0 0 30px; }

.file-reference > p, .file-reference li {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.8);
  line-height: 1.7; }

.file-reference ul {
  -webkit-padding-start: 30px;
          padding-inline-start: 30px; }
  .file-reference ul li {
    margin: 5px 0; }

.file-reference .member-summary > h2 {
  margin: 40px 0 10px 0; }

.file-reference .seealso {
  font-size: 16px;
  margin: 30px 0; }
  .file-reference .seealso strong {
    font-weight: 400; }
  .file-reference .seealso li p {
    margin: 0;
    line-height: 1; }
  .file-reference .seealso ul {
    margin: 5px 0; }
  .file-reference .seealso p {
    font-size: 16px; }

.spinner {
  display: block;
  position: relative; }
  .spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    border-radius: 50%;
    -webkit-animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite; }
  .spinner div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s; }
  .spinner div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s; }
  .spinner div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s; }

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.release-view {
  margin-top: 60px; }
  .release-view strong {
    border-right: 1px solid rgba(0, 0, 0, 0.3);
    margin-right: 20px;
    padding-right: 20px; }
  .release-view time {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.5);
    font-size: 16px; }
  .release-view h2 {
    margin-bottom: 0;
    font-size: 30px;
    display: flex;
    align-items: center; }
  .release-view hr {
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    margin: 10px 0 0; }
  .release-view .release-notes {
    font-size: 16px;
    overflow-x: scroll;
    padding-left: 20px; }
    .release-view .release-notes > ul {
      margin-top: 0; }
    .release-view .release-notes > p {
      margin: 30px 0 10px; }

.releases-page .spinner-container {
  margin-top: 60px;
  max-width: 320px; }

.releases-page .spinner {
  margin: 0 auto; }

.troubleshooting-page p {
  margin: 40px 0; }

.troubleshooting-page li {
  margin: 20px 0; }

.app {
  padding-bottom: 100px; }
  .app .developers-navbar {
    background-color: #12bae9; }
  .app .app-container {
    width: 90vw;
    max-width: 1200px;
    margin: 80px auto 0 auto;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 900.001px) {
      .app .app-container {
        display: block; } }
  @media (min-width: 900.001px) {
    .app .page-container {
      /*
      * String interpolation is needed here due to a Sass issue:
      * https://stackoverflow.com/questions/17982111/sass-variable-in-css-calc-function
      */
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(90vw - 280px);
      width: calc(90vw - 280px); } }
  @media (min-width: 1320px) {
    .app .page-container {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(1200px - 280px);
      width: calc(1200px - 280px); } }

.link-font, a {
  color: #16aed0;
  font-weight: 400; }
  b > .link-font, b > a {
    font-weight: inherit; }

html, #root {
  height: 100%;
  width: 100%; }

body {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0; }

@font-face {
  font-family: 'Futura';
  src: local("Futura"), url(/static/media/futura.352a13d3.woff2) format("woff2"); }

body {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  color: rgba(0, 0, 0, 0.8);
  margin: 0;
  padding: 0;
  line-height: 1.5;
  font-weight: 300;
  font-size: 18px; }

h1, h2, h3 {
  font-family: 'Futura'; }

h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a {
  color: inherit;
  font: inherit; }

a {
  text-decoration: none; }
  a:hover {
    text-decoration: underline; }

h1, h2, h3, h4 {
  margin: 0 0 20px 0; }

h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.8); }

h2 {
  font-size: 22px; }

p {
  margin: 20px 0 20px 0; }

code {
  font-family: 'Roboto Mono', monospace;
  padding: 2px 3px 1px;
  border-radius: 3px;
  color: #333; }
  a > code {
    color: #16aed0; }

pre > code {
  border-radius: 10px;
  padding: 10px 15px !important; }

