@import './web-common/styles/page-base.scss';
@import './variables.scss';

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:400,500');

h1, h2, h3, h4 {
  margin: 0 0 20px 0;
}

h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.8);
}

h2 {
  font-size: 22px;
}

p {
  margin: 20px 0 20px 0;
}

code {
  font-family: 'Roboto Mono', monospace;
  padding: 2px 3px 1px;
  // background-color: #f7f7f9;
  border-radius: 3px;
  color: $code-color;

  a > & {
    color: $link-color;
  }
}

pre > code {
  border-radius: 10px;
  padding: 10px 15px !important;
}
