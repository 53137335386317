
.code-example-box {
  background-color: #eaeef3;
  border-radius: 10px;
  padding-top: 10px;

  h4 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
    margin-left: 15px;
    color: rgba(0, 0, 0, 0.6);
    font-style: italic;
  }

  pre {
    margin-top: 0;
    font-size: 16px;
  }
}
