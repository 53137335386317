
.carousel-component {

  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    flex: 0 0 80%;
    position: relative;

    > div {
      position: absolute; top: 0; right: 0; bottom: 0; left: 0;
      overflow-x: hidden;
      display: flex;
      align-items: center;
    }
  }

  button {
    flex: 0 0 10%;
    min-width: 30px;
    max-width: 50px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    img {
      width: 100%;
      opacity: 0.5;
    }

    &:first-child {
      transform: rotate(90deg);
    }
    &:last-child {
      transform: rotate(-90deg);
    }
  }

  .carousel-component-item {
    position: absolute;
  }
}
