@import './link-font.scss';
@import './variables.scss';

html, #root { height:100%; width: 100% }
body { position: absolute; top:0; bottom:0; right:0; left:0; }

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

@font-face {
  font-family: 'Futura';
  src: local('Futura'), url(./web-common/fonts/futura.woff2) format('woff2');
}

body {
  font-family: $default-font;
  color: rgba(0, 0, 0, 0.8);
  margin: 0;
  padding: 0;
  line-height: 1.5;
  font-weight: 300;
  font-size: 18px;
}

h1, h2, h3 {
  font-family: 'Futura';
}

// For headers that link to their ID
h1,h2,h3,h4,h5,h6 {
  > a {
    color: inherit;
    font: inherit;
  }
}

a {
  @extend .link-font;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
