
.troubleshooting-page {
  p {
    margin: 40px 0;
  }

  li {
    margin: 20px 0;
  }
}
