@import '../../shared-styles/page.scss';

.webview-overview {
  @extend .page;

  h1 {
    font-family: Roboto;
    font-weight: 300;
    font-size: 31px;

    strong {
      font-family: Futura;
      font-size: 32px;
    }
  }

  .store-header {
    font-size: 16px;
    font-weight: bold;
    margin: 40px 0 10px;
    font-family: 'Roboto';
  }

  > video {
    display: block;
    width: 100%;
    max-width: 862px;
    border-radius: 6px;
    margin: 30px 0;
  }
}
