
@import './variables.scss';

.sidebar {
  width: $sidebar-width;
  flex: 0 0 $sidebar-width;

  @media (max-width: $sidebar-collapse-threshold) {
    display: none;
  }

  &.mobile-only-sidebar {
    display: none;
  }

  &.mobile-open-container {
    position: fixed; top: 50px; bottom: 0; left: 0;
    // High z-index to ensure it's in front of the Android button.
    z-index: 10;
    display: block;
  }

  .mobile-only {
    display: none;
  }

  .mobile-open {
    height: 100%;
    overflow-y: scroll;

    .mobile-only {
      display: block;
    }
  }

  .mobile-sidebar-backdrop {
    position: fixed; top: 0; right: 0; bottom: 0; left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }

  .desktop-sidebar {
    border-radius: 6px;
  }

  aside {
    background-color: #edf5f7;
    padding: 15px 0;

    .sidebar-item {
      display: block;
      color: rgba(0, 0, 0, 0.8);
      font-size: 16px;
      margin: 15px;
      font-weight: 500;
    }

    a {
      @extend .sidebar-item;

      &:hover {
        &::after {
          display: none;
        }
      }
    }

    h2 {
      @extend .sidebar-item;
      font-family: unset;
      margin-bottom: 10px;
    }

    h3 {
      margin: 15px 0 10px;
      font-size: 14px;
      font-weight: 400;
      font-family: unset;
      opacity: 0.5;
    }

    a[href]:hover {
      text-decoration: underline;
    }

    ul {
      list-style-type: none;
      padding-inline-start: 0;
      a {
        font-weight: 300 !important;
        opacity: 0.85;
        &:first-child {
          margin-top: 5px;
        }
      }
    }

    .file-section-list {
      padding-inline-start: 30px;
      margin: 0;
    }
  }
}

