
.member-table {

  margin: 40px 0;

  h3 {
    font-size: 18px;
    font-weight: 500;
    background-color: #edf5f7;
    padding: 12px 8px;
    margin: 0;
  }

  > div {
    font-family: 'Roboto Mono';
    font-size: 16px;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-top: none;
    overflow-x: scroll;
    white-space: nowrap;

    a::after {
      display: none;
    }
    a:hover::after {
      display: block;
    }
  }
}
