@import '../../../web-common/styles/variables.scss';

.additional-interfaces {
  color: rgba(0, 0, 0, 0.6);
  margin: 10px 0 40px 30px;
  position: relative;
  span {
    font-size: 16px;
    position: relative;
  }
  ul {
    margin: 5px 0;
    list-style-type: '- ';
  }
  li {
    line-height: 1;
    margin: 10px 0;
  }
  a {
    font-weight: 300;
  }

  .help-link {
    width: 15px;
    height: 15px;
    background-color: lighten($theme-color-1, 20%);
    border-radius: 50%;
    color: white;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    &:hover {
      text-decoration: none;
      background-color: $theme-color-1;
    }
  }
}
