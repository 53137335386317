
.customers-section {

  max-width: 920px;

  a {
    img {
      width: 100%;
    }
  }
}
