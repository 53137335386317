
$theme-color-1: #12bae9;
$link-color: #16aed0;
$main-font: 'Roboto', Helvetica, Arial, sans-serif;
$accent-font: 'Futura';
$code-font-family: 'Roboto Mono', monospace;
$code-color: #333;
$page-content-width: 90vw;
$page-content-max-width: 1200px;
$page-width-at-which-content-is-at-max-width: 1.1 * $page-content-max-width;
